import React from 'react';
import { Link } from 'react-router-dom';

function Main({ title, subtitle, same }) {
  return (
    <div
      className="bread_crumb"
      data-aos="fade-in"
      data-aos-duration={2000}
      data-aos-delay={100}
    >
      <div className="container">
        <div className="bred_text">
          <h1>{subtitle}</h1>
          <ul>
            <li>
              <Link to="/">{title}</Link>
            </li>
            <li>
              <span>»</span>
            </li>
            <li>
              <Link to="/nosotros">{same}</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Main;
