//src/components/Home/Main.js

import React from 'react';
import Banner from '../../components/Banner/Banner';
import PrincipalesCaracterísticas from '../../components/PrincipalesCaracterísticas/PrincipalesCaracterísticas';
import Features from '../../components/Solucion/Solucion';
import SolucionEntradaDeDatos from '../../components/Solucion/SolucionEntradaDeDatos';
import Collaborate from '../../components/Solucion/SolucionProcesamientoDeDatos';
import SolutionSalidaDeDatos from '../../components/Solucion/SolutionSalidaDeDatos';
import AiManagerSection from '../../components/AiManager/AiManagerSection';
import Featured from '../../components/Service/Featured';

const Main = () => {
  return (
    <>
      <div className="top_home_wraper white_option">
        <Banner />
        <PrincipalesCaracterísticas />
        <Features />
        <SolucionEntradaDeDatos />
        <Collaborate />
        <SolutionSalidaDeDatos />
        <AiManagerSection />
        <Featured />
      </div>
    </>
  );
};

export default Main;
