import React from 'react';

function Mapa() {
  return (
    <>
      <section className="row_am a_section">
        <div className="container">
          <div className="map_inner">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3176.4594993809673!2d-4.674345584684362!3d36.50511398000483!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd72f796f95c57a5%3A0xd8f723a8be9d0b08!2sC.%20Flandes%2C%2011%2C%2029650%20Las%20Lagunas%20de%20Mijas%2C%20M%C3%A1laga%2C%20Spain!5e0!3m2!1sen!2sin!4v1664399300741!5m2!1sen!2sin"
              style={{ border: 0, width: '100%', height: 510 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="mapa"
            ></iframe>
          </div>
        </div>
      </section>
    </>
  );
}

export default Mapa;
