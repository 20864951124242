import React from 'react';
// import { Link } from 'react-router-dom';
// import My from '../../assets/images/new/service-img.png';

function InteraccionesMetodos() {
  return (
    <>
      <section className="service_detail_section">
        <div className="container">
          <div className="service_left_side">
            <p data-aos="fade-up" data-aos-duration={1500}>
              Bienvenido a un ecosistema donde cada detalle cuenta. Desde
              chatear con tu asistente virtual, hasta rastrear eventos en tiempo
              real, AiManager es la puerta a un entendimiento más profundo de tu
              negocio.
            </p>
            <ul
              className="list_block"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <li>
                <h3>AiMentor: Tu Asistente IA Personalizado</h3>
                <p>
                  Comunicarse con AiMentor es como hablar con un experto en
                  datos que conoce tu negocio. Puedes preguntarle sobre los
                  problemas detectados, cómo mejorarlos y solicitar informes y
                  análisis específicos para aclarar cualquier duda o implementar
                  cambios.
                </p>
              </li>
              <li>
                <h3>Detección por Cámara Inteligente</h3>
                <p>
                  Recibe notificaciones inteligentes gracias al poder de la IA.
                  Esta función puede aprender a detectar eventos, reconocer
                  patrones y comportamientos, identificar procedimientos y
                  emociones, e incluso adaptarse a diversos intervalos de
                  tiempo, adaptándose a las necesidades específicas de tu
                  empresa.
                </p>
              </li>
              <li>
                <h3>Geolocalización Avanzada</h3>
                <p>
                  Sigue más que simples coordenadas; conecta la ubicación con el
                  contexto. Combina la geolocalización con la detección visual
                  para una comprensión precisa de dónde y por qué ocurren
                  eventos. Conéctala con otros sensores y crea un mapa de
                  inteligencia multifuncional para tu negocio.
                </p>
              </li>
              {/* <li>
                <h3>Ontime delivery</h3>
                <p>
                  Simply dummy text of the printing and typesetting inustry
                  lorem Ipsum has Lorem dollar summit.
                </p>
              </li> */}
            </ul>
            {/* <h3 data-aos="fade-up" data-aos-duration={1500}>
              How we build your software
            </h3>
            <p data-aos="fade-up" data-aos-duration={1500}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry lorem Ipsum has been the industrys standard dummy text
              ever since the when an utext ever since the when an unknown
              printer took a galley of type and.
            </p> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default InteraccionesMetodos;
