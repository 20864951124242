import React from 'react';

// Multimedia
// import One from '../../assets/images/new/soution_icon1.png';
// import Two from '../../assets/images/new/soution_icon2.png';
// import Three from '../../assets/images/new/soution_icon3.png';
import { GiCctvCamera } from 'react-icons/gi';
import { LuBrainCircuit } from 'react-icons/lu';
import { SiAlwaysdata } from 'react-icons/si';

const data = [
  {
    // camera 30px width color red
    img: <GiCctvCamera size={36} color="#ec3755" />,
    title: 'Captura y Categorización de Datos',
    desc: 'Recopilamos datos de cámaras, maquinarias y sitios web en tiempo real.',
  },
  {
    img: <LuBrainCircuit size={36} color="#05c689" />,
    title: 'Procesamiento e Integración de Datos',
    desc: 'Transformamos y clasificamos datos para extraer información valiosa.',
  },
  {
    img: <SiAlwaysdata size={36} color="#fbab12" />,
    title: 'Entrega y Aplicación de Datos',
    desc: 'Proporcionamos insights prácticos para decisiones informadas y ágiles.',
  },
];

function Features() {
  return (
    <>
      <section className="solution_section">
        <div className="solution_inner">
          <div className="container">
            <div
              className="section_title"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <h2>Solución</h2>
              <p>
                Nos encargamos de capturar, procesar y aplicar tus datos para
                generar insights accionables. <br /> Nuestra solución se basa en
                tres pilares.
              </p>
            </div>
            <div className="row">
              {data.map((item, index) => (
                <div className="col-md-4" key={index}>
                  <div
                    className="solution_cards"
                    data-aos="fade-up"
                    data-aos-duration={1500}
                  >
                    <div className="icon">{item.img}</div>
                    <div className="text">
                      <h3>{item.title}</h3>
                      <p>{item.desc}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Features;
