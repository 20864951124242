import React from 'react';
// import { Link } from 'react-router-dom';
import BigData from '../../assets/images/new/big_data.png';
import Brain2 from '../../assets/images/brain2.png';
import Brain from '../../assets/images/brain.png';
import MachineLearning from '../../assets/images/new/machine-learning.png';
import SecurelyLogo from '../../assets/images/new/securely.svg';

const SolucionProcesamiento = () => {
  return (
    <>
      <section className="row_am collaborate_section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div
                className="collaborate_image"
                data-aos="fade-in"
                data-aos-duration={2000}
                data-aos-delay={100}
              >
                <div
                  className="img_block"
                  data-aos="fade-in"
                  data-aos-duration={2000}
                  data-aos-delay={100}
                >
                  <img
                    className="icon_img moving_animation"
                    src={BigData}
                    alt="imagessssss"
                  />
                  <img src={Brain2} alt="imagesss" />
                </div>
                <div
                  className="img_block"
                  data-aos="fade-in"
                  data-aos-duration={2000}
                  data-aos-delay={100}
                >
                  <img src={Brain} alt="imagesss" />
                  <img
                    className="icon_img moving_animation"
                    src={MachineLearning}
                    alt="imagesss"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="collaborate_text"
                data-aos="fade-in"
                data-aos-duration={2000}
                data-aos-delay={100}
              >
                <span className="icon">
                  <img src={SecurelyLogo} alt="imagesss" />
                  {/* <GiBrain /> */}
                </span>
                <div className="section_title">
                  <h2>Procesamiento e Integración de Datos</h2>
                  <p>
                    Transformamos tus datos en información valiosa,
                    proporcionándote la ventaja competitiva que necesitas para
                    destacar. Diseñamos y configuramos la infraestructura
                    necesaria para procesar estos datos en la nube.
                  </p>
                </div>
                <ul>
                  <li data-aos="fade-up" data-aos-duration={2000}>
                    <h3>Aprendizaje Automático (Machine Learning)</h3>
                    <p>
                      Utilizamos modelos de machine learning para personalizar y
                      clasificar tus datos.
                    </p>
                  </li>
                  <li data-aos="fade-up" data-aos-duration={2000}>
                    <h3>Regresión y Clasificación</h3>
                    <p>
                      Aplicamos técnicas de regresión y clasificación para
                      analizar y predecir tendencias.
                    </p>
                  </li>
                  <li data-aos="fade-up" data-aos-duration={2000}>
                    <h3>Análisis de Big Data</h3>
                    <p>
                      Extraemos información de tus datos con estrategias
                      avanzadas de análisis de Big Data.
                    </p>
                  </li>
                  <li data-aos="fade-up" data-aos-duration={2000}>
                    <h3>Infraestructura Cloud</h3>
                    <p>
                      Creamos una infraestructura segura y escalable en
                      servicios de nube populares como AWS, Google Cloud
                      Platform y Microsoft Azure.
                    </p>
                  </li>
                  <li data-aos="fade-up" data-aos-duration={2000}>
                    <h3>DevOps e Integración de Tecnologías</h3>
                    <p>
                      Integramos estos procesos en tu sistema existente de
                      manera fluida.
                    </p>
                  </li>
                </ul>

                {/* <Link
                  to="/service-detail"
                  data-aos="fade-up"
                  data-aos-duration={2000}
                  className="btn btn_main"
                >
                  READ MORE <i className="icofont-arrow-right" />
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SolucionProcesamiento;
