import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../pages/Home/Main';
// import Nosotros from '../pages/Nosotros/Main';
import Contacto from '../pages/Contacto/Main';
import AiManager from '../pages/AiManager/Main';
import Servicios from '../pages/Servicios/Main';
import IndustriesList from '../components/Industrias/IndustriesList';
import HistoriaSeguridadCajeros from '../components/Historias/HistoriaSeguridadCajeros';
import HistoriaTecnologiaMedicina from '../components/Historias/HistoriaTecnologiaMedicina';
import HistoriaAlmacenCongelados from '../components/Historias/HistoriaAlmacenCongelados';
import HistoriaMaquinariaVial from '../components/Historias/HistoriaMaquinariaVial';
import HistoriaDeteccionGasolinaIA from '../components/Historias/HistoriaDeteccionGasolinaIA';
import Layout from '../components/Layout';
import Error404 from '../components//Error/Error';

function Index() {
  return (
    <>
      <div className="page_wrapper">
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/industrias" element={<Servicios />}>
              <Route index element={<IndustriesList />} />
              <Route
                path="historia/seguridad-cajeros"
                element={<HistoriaSeguridadCajeros />}
              />
              <Route
                path="historia/tecnologia-medicina"
                element={<HistoriaTecnologiaMedicina />}
              />
              <Route
                path="historia/almacen-congelados"
                element={<HistoriaAlmacenCongelados />}
              />
              <Route
                path="historia/maquinaria-vial"
                element={<HistoriaMaquinariaVial />}
              />
              <Route
                path="historia/deteccion-gasolina"
                element={<HistoriaDeteccionGasolinaIA />}
              />
            </Route>
            <Route path="/ai-manager" element={<AiManager />} />
            {/* <Route path="/nosotros" element={<Nosotros />} /> */}
            <Route path="/contacto" element={<Contacto />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </Layout>
      </div>
    </>
  );
}

export default Index;
