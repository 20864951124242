import React from 'react';
import { Link } from 'react-router-dom';
// import One from '../../assets/images/new/orange_play.png';
import AIM_branch from '../../assets/images/AIM_branch.png';
import AIM_metrics from '../../assets/images/AIM_metrics.png';
import AIM_mobile from '../../assets/images/AIM_mobile_sm.png';
import CountUp from 'react-countup';

const AiManagerSection = () => {
  return (
    <>
      <section className="about_us_section">
        <div className="dotes_anim_bloack">
          <div className="dots dotes_1" />
          <div className="dots dotes_2" />
          <div className="dots dotes_3" />
          <div className="dots dotes_4" />
          <div className="dots dotes_5" />
          <div className="dots dotes_6" />
          <div className="dots dotes_7" />
          <div className="dots dotes_8" />
        </div>
        <div className="container">
          <div className="about_block">
            <div className="row">
              <div className="col-md-6">
                <div
                  className="section_title"
                  data-aos="fade-in"
                  data-aos-duration={1500}
                >
                  <h2>
                    Unificación, administración e interacción con tus datos
                    originados en IA
                  </h2>
                  <p>
                    AiManager ofrece una gestión unificada de todos los datos
                    obtenidos a través de las funcionalidades de Inteligencia
                    Artificial, optimizando el manejo de tu ecosistema
                    empresarial.
                  </p>
                  <p>
                    Esta plataforma no solo brinda información, sino que también
                    es un tablero de comando donde las funcionalidades de IA se
                    transforman en datos valiosos.
                  </p>
                  <p>
                    Estos datos te permiten analizar y entender las dinámicas y
                    patrones de tu empresa, facilitando la toma de decisiones
                    ágiles y promoviendo acciones de mejora continua.
                  </p>
                  <div className="cta_more">
                    <Link to="/sasda" className="btn">
                      Ver más
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="abt_img_block"
                  data-aos="fade-in"
                  data-aos-duration={1500}
                >
                  {/* <Link
                    data-aos="fade-up"
                    data-aos-duration={1500}
                    className="popup-youtube play-button"
                    data-url="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&mute=1"
                    data-toggle="modal"
                    data-target="#myModal"
                    title="XJj2PbenIsU"
                  >
                    <div className="play_btn">
                      <img src={One} alt="imagesss" />
                      <div className="waves-block">
                        <div className="waves wave-1" />
                        <div className="waves wave-2" />
                        <div className="waves wave-3" />
                      </div>
                    </div>
                  </Link> */}
                  <div className="bottom_img">
                    <img src={AIM_mobile} alt="imagesss" />
                  </div>
                  <div className="top_img">
                    <img src={AIM_metrics} alt="imagesss" />
                    <img src={AIM_branch} alt="imagesss" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="counter">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div
                  className="counter_outer"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                >
                  <div className="counter_box">
                    <p className="d-flex justify-content-center">
                      <CountUp
                        className="counter-value"
                        data-count={120}
                        end={450}
                        delay={0.5}
                      >
                        0
                      </CountUp>
                      <span>+</span>
                    </p>
                    <p>Interactúa con una IA informada con tus datos</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div
                  className="counter_outer"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                >
                  <div className="counter_box">
                    <p className="d-flex justify-content-center">
                      <CountUp
                        className="counter-value"
                        data-count={120}
                        end={120}
                        delay={1}
                      >
                        0{' '}
                      </CountUp>
                      <span>+</span>
                    </p>
                    <p>Reacciona ante eventos captados por cámaras</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div
                  className="counter_outer"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                >
                  <div className="counter_box">
                    <p className="d-flex justify-content-center">
                      <CountUp
                        className="counter-value"
                        data-count={120}
                        end={135}
                        delay={1}
                      >
                        0{' '}
                      </CountUp>
                      <span>+</span>
                    </p>
                    <p>Activa respuestas automáticas en tu negocio</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div
                  className="counter_outer"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                >
                  <div className="counter_box">
                    <p className="d-flex justify-content-center">
                      <CountUp
                        className="counter-value"
                        data-count={120}
                        end={324}
                        delay={1}
                      >
                        0{' '}
                      </CountUp>
                      <span>+</span>
                    </p>
                    <p>Analiza a la competencia mediante BrandScan</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AiManagerSection;
