import React from 'react';
// import { Link } from 'react-router-dom';
import One from '../../assets/images/new/banner_01.jpeg';
import Two from '../../assets/images/new/banner_02.jpg';
import Three from '../../assets/images/new/banner_03.png';
import Four from '../../assets/images/new/banner_04.png';

function Banner() {
  return (
    <>
      <section className="banner_section">
        <div className="container">
          <div className="banner_text">
            <div
              className="ban_inner_text"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              {/* <h1>
                                <span>Grow your software</span> <br /> faster &amp; easier with
                                Dashbord
                            </h1> */}
              <h1>
                <span>Implementamos soluciones de</span> <br /> Inteligencia
                Artificial
              </h1>

              <p>
                para transformar tu negocio y desbloquear el valor de tus datos.
              </p>
            </div>
            {/* <div className="btn_group">
              <Link
                to="/contacto"
                className="btn btn_main"
                data-aos="fade-right"
                data-aos-duration={1500}
              >
                START FREE TRIAL <i className="icofont-arrow-right" />
              </Link>
              <Link
                to="/contacto"
                className="btn btn_main"
                data-aos="fade-left"
                data-aos-duration={1500}
              >
                REQUEST DEMO <i className="icofont-arrow-right" />
              </Link>
            </div> */}
            {/* <div className="offers" data-aos="fade-up" data-aos-duration={1500}>
              <span>
                <i className="icofont-check-circled" /> Free trial 14 days
              </span>
              <span>
                <i className="icofont-check-circled" /> No credit card require
              </span>
            </div> */}
          </div>
          <div
            className="banner_images"
            data-aos="fade-up"
            data-aos-duration={1500}
          >
            <img src={One} alt="imagesss" />
            <div className="sub_images">
              <img className="moving_animation" src={Two} alt="imagesss" />
              <img className="moving_animation" src={Three} alt="imagesss" />
              <img className="moving_animation" src={Four} alt="imagesss" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Banner;
