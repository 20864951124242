import React from 'react';
import Bredcom from '../../components/Bredcom/Main';
import InteraccionAiManager from '../../components/AiManager/Interaccion';
import InteraccionesMetodos from '../../components/AiManager/InteraccionesMetodos';
import InteraccionesManager from '../../components/AiManager/InteraccionesManager';
import InteraccionAiManager2 from '../../components/AiManager/Interaccion2';

function Main() {
  return (
    <>
      <div className="inner_page_block white_option">
        <Bredcom
          title={'Inicio'}
          subtitle={'Interacción Avanzada con IA'}
          same={'Interacción con la IA'}
        />
      </div>
      <InteraccionAiManager />
      <InteraccionesMetodos />
      <InteraccionAiManager2 />
      <InteraccionesManager />
    </>
  );
}

export default Main;
