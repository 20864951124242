// src/components/Service/Main.js

import React from 'react';
import Bredcom from '../../components/Bredcom/Main';
import { Outlet } from 'react-router-dom';

function Main() {
  return (
    <>
      <div className="inner_page_block white_option">
        <Bredcom title={'Inicio'} subtitle={'Servicios'} same={'Servicios'} />
      </div>
      <Outlet />
    </>
  );
}

export default Main;
