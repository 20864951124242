import React from 'react';
import AIM_branch from '../../assets/images/AIM_branch.png';

import { BsCheckCircleFill } from 'react-icons/bs';

function InteraccionAiManager2() {
  return (
    <>
      <section className="row_am why_choose_section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div
                className="why_choose_text"
                data-aos="fade-in"
                data-aos-duration={2000}
                data-aos-delay={100}
              >
                <div className="section_title">
                  <h2>Administración y Colaboración</h2>
                  <p>
                    Más allá de la mera recopilación de datos, AiManager
                    promueve la colaboración activa. Integra datos tanto
                    internos como externos y redefine cómo tu equipo interactúa,
                    gestiona la información y formula estrategias. AiManager
                    actúa como un hub de trabajo colaborativo, preparando el
                    terreno para una interacción dinámica con las siguientes
                    funciones:
                  </p>
                </div>
                <ul data-aos="fade-up" data-aos-duration={2000}>
                  <li>
                    <BsCheckCircleFill color="orange" className="mr-2" />
                    <b>BrandScan:</b> Obtén una visión clara de tus competidores
                    y toma decisiones basadas en datos reales.
                  </li>
                  <li>
                    <BsCheckCircleFill color="orange" className="mr-2" />
                    <b>Interacción y Respuesta:</b>
                    captura y actúa según el feedback de tus clientes y
                    auditores, transformando opiniones positivas en activos
                    digitales.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="solution_image"
                data-aos="fade-in"
                data-aos-duration={2000}
                data-aos-delay={100}
              >
                <img src={AIM_branch} alt="imagesss" />
              </div> 
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default InteraccionAiManager2;
