// src/assets/data/stories.js
import CajeroAutomatico from '../../assets/images/new/cajero-automatico.jpg';
import AlmacenIndustrial from '../../assets/images/new/almacen-industrial.jpeg';
import MaquinariaVial from '../../assets/images/new/maquinaria-vial.jpg';
import DeteccionGasolina from '../../assets/images/new/deteccion-gasolina.jpg';

const stories = [
  {
    id: 'seguridad-cajeros',
    date: '12 Dec, 2022',
    title:
      'Implementación de sistemas avanzados de seguridad para cajeros automáticos',
    text: 'Empresa líder en España innova en seguridad bancaria con la implementación de Inteligencia Artificial y IoT en cajeros automáticos, abordando desafíos como costos operativos, tiempos de respuesta y normativas de seguridad.',
    industry: 'Seguridad y Finanzas',
    coverImage: CajeroAutomatico,
    storyLink: 'http://www.logiko.ai/servicios/historia/seguridad-cajeros',
  },
  {
    id: 'almacen-congelados',
    date: '15 Feb, 2023',
    title: 'Automatización de un Almacén Industrial de Congelados con IA',
    text: 'Logiko.AI revoluciona el almacenamiento de congelados, asegurando condiciones óptimas mediante el uso de IA, monitoreo en tiempo real y análisis predictivo en ambientes donde robots y humanos coexisten.',
    industry: 'Manufactura',
    coverImage: AlmacenIndustrial,
    storyLink: 'http://www.logiko.ai/servicios/historia/almacen-congelados',
  },
  {
    id: 'maquinaria-vial',
    date: '20 Mar, 2023',
    title: 'Innovación en Maquinaria Vial: Integración de IA y IoT',
    text: 'Una empresa líder en maquinaria vial revoluciona la seguridad y eficiencia de pavimentación integrando IA y IoT, reduciendo accidentes y proporcionando datos en tiempo real a operadores y supervisores.',
    industry: 'Automotriz',
    coverImage: MaquinariaVial,
    storyLink: 'http://www.logiko.ai/servicios/historia/maquinaria-vial',
  },
  {
    id: 'deteccion-gasolina',
    date: '17 Sep, 2023',
    title:
      'Detección Automatizada de Derrames de Gasolina en Estaciones de Servicio',
    text: 'Logiko.AI se embarca en una misión innovadora para abordar uno de los problemas críticos en las estaciones de servicio: la detección eficiente de derrames de gasolina. Mediante la combinación de IA y tecnología de visión por computadora, se espera revolucionar la seguridad y la eficiencia operacional en estaciones de servicio.',
    industry: 'Energía y Servicios',
    coverImage: DeteccionGasolina,
    storyLink: 'http://www.logiko.ai/servicios/historia/deteccion-gasolina',
  },
  // Agregar más objetos para las siguientes historias...
];

export default stories;
