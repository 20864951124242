import React from 'react';
import Bredcom from '../../components/Bredcom/Main';
import CanalesDeContacto from '../../components/Contacto/CanalesDeContacto';
import FormularioContacto from '../../components/Contacto/FormularioContacto';
import Mapa from '../../components/Contacto/Mapa';

function Main() {
  return (
    <>
      <div className="inner_page_block white_option">
        <Bredcom title={'Inicio'} subtitle={'Contacto'} same={'Contacto'} />
      </div>
      <CanalesDeContacto />
      <FormularioContacto />
      <Mapa />
    </>
  );
}

export default Main;
