// src/components/Contacto/FormularioContacto.js

import React, { useState, useRef } from 'react';

import emailjs from '@emailjs/browser';
import One from '../../assets/images/new/paperplane.png';

// import { faArrowRight } from 'react-icons/fa';

const FormularioContacto = () => {
  // initialize emailjs
  const [formData, setFormData] = useState({
    nombre: '',
    email: '',
    empresa: '',
    pais: '',
    telefono: '',
    sitioWeb: '',
    mensaje: '',
    acepto: false,
  });

  // Nuevo estado para manejar el resultado del envío
  const [submitStatus, setSubmitStatus] = useState('pending'); // posibles valores: 'pending', 'success', 'error'

  // handle form submission
  const handleChange = (e) => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setFormData({
      ...formData,
      [e.target.name]: value,
    });
  };

  // No recargar la página al enviar el formulario
  const formRef = useRef(null);
  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitStatus('pending');

    emailjs
      .sendForm(
        'service_apev0w4',
        'template_th12g2l',
        formRef.current,
        'oDAGAU-O93dKEqArx'
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          setSubmitStatus('success');
          console.log(formData);
        },
        (error) => {
          console.log('FAILED...', error);
          setSubmitStatus('error');
          console.log(formData);
        }
      );
  };

  return (
    <>
      <section className="contact_form_section">
        <div className="container">
          <div className="contact_inner">
            <div className="contact_form">
              <div className="section_title">
                <h2>
                  Deja un <span>mensaje</span>
                </h2>
                <p>
                  Rellena el formulario abajo, nuestro equipo responderá pronto.
                </p>
              </div>
              <form ref={formRef} onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="nombre"
                        placeholder="Nombre"
                        className="form-control"
                        value={formData.nombre}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        placeholder="Correo Electrónico"
                        className="form-control"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="empresa"
                        placeholder="Nombre de la Empresa"
                        className="form-control"
                        value={formData.empresa}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <select
                        className="form-control"
                        name="pais"
                        value={formData.pais}
                        onChange={handleChange}
                      >
                        <option value="">País</option>
                        <option value="Alemania">Alemania</option>
                        <option value="Argentina">Argentina</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Arabia Saudita">Arabia Saudita</option>
                        <option value="Bélgica">Bélgica</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Brasil">Brasil</option>
                        <option value="Canadá">Canadá</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Colombia">Colombia</option>
                        <option value="Corea del Sur">Corea del Sur</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Dinamarca">Dinamarca</option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Emiratos Árabes Unidos">
                          Emiratos Árabes Unidos
                        </option>
                        <option value="España">España</option>
                        <option value="Estados Unidos">Estados Unidos</option>
                        <option value="Francia">Francia</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Holanda">Holanda</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="India">India</option>
                        <option value="Irlanda">Irlanda</option>
                        <option value="Israel">Israel</option>
                        <option value="Italia">Italia</option>
                        <option value="Japón">Japón</option>
                        <option value="Malasia">Malasia</option>
                        <option value="México">México</option>
                        <option value="Nueva Zelanda">Nueva Zelanda</option>
                        <option value="Noruega">Noruega</option>
                        <option value="Panamá">Panamá</option>
                        <option value="Perú">Perú</option>
                        <option value="Polonia">Polonia</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Reino Unido">Reino Unido</option>
                        <option value="República Dominicana">
                          República Dominicana
                        </option>
                        <option value="Rusia">Rusia</option>
                        <option value="Singapur">Singapur</option>
                        <option value="Sudáfrica">Sudáfrica</option>
                        <option value="Suecia">Suecia</option>
                        <option value="Suiza">Suiza</option>
                        <option value="Taiwán">Taiwán</option>
                        <option value="Uruguay">Uruguay</option>
                        <option value="Venezuela">Venezuela</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="telefono"
                        placeholder="Teléfono"
                        className="form-control"
                        value={formData.telefono}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="sitioWeb"
                        placeholder="Sitio Web"
                        className="form-control"
                        value={formData.sitioWeb}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    name="mensaje"
                    placeholder="Tu mensaje"
                    value={formData.mensaje}
                    onChange={handleChange}
                    defaultValue={''}
                  />
                </div>
                <div className="form-group term_check">
                  <input
                    type="checkbox"
                    id="term"
                    name="acepto"
                    value={formData.acepto}
                    onChange={handleChange}
                  />
                  <label htmlFor="term">
                    Acepto recibir correos electrónicos, boletines y mensajes
                    promocionales
                  </label>
                </div>
                <div className="form-group ">
                  {submitStatus === 'pending' && (
                    <button className="btn btn_main" type="submit">
                      ENVIAR MENSAJE <faArrowRight />
                    </button>
                  )}
                  {submitStatus === 'success' && (
                    <div>Mensaje enviado con éxito!</div>
                  )}
                  {submitStatus === 'error' && (
                    <div>
                      Hubo un error al enviar el mensaje. Por favor, inténtalo
                      de nuevo.
                    </div>
                  )}
                </div>
              </form>
              <div className="form-graphic">
                <img src={One} alt="imagen" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FormularioContacto;
