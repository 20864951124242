// src/components/Historias/HistoriaDeteccionGasolinaIA.js

import React from 'react';
import HojaImg from '../../assets/images/hoja.png';
import ResultadoImg from '../../assets/images/resultado.png';
import SolucionImg from '../../assets/images/solucion.png';
import DesafioImg from '../../assets/images/desafio.png';

function HistoriaDeteccionGasolinaIA() {
  return (
    <>
      <section
        className="blog_detail_section"
        data-aos="fade-up"
        data-aos-duration={2000}
        data-aos-delay={200}
      >
        <div className="container">
          <div className="blog_inner_pannel">
            <div
              className="blog_info"
              data-aos="fade-up"
              data-aos-duration={2000}
            >
              <span className="date">17 Sep, 2023</span>
              <h1>
                Detección Automatizada de Derrames de Gasolina en Estaciones de
                Servicio
              </h1>
            </div>

            <div
              className="info_section"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="d-flex align-items-center mb-3">
                <img
                  src={HojaImg}
                  alt="Visión General"
                  className="section_image mr-3"
                />
                <h2>Visión General</h2>
              </div>
              <hr />
              <p>
                Logiko.AI se embarca en una misión innovadora para abordar uno
                de los problemas críticos en las estaciones de servicio: la
                detección eficiente de derrames de gasolina.
              </p>
            </div>

            <div
              className="info_section"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="d-flex align-items-center mb-3">
                <img
                  src={DesafioImg}
                  alt="Desafíos"
                  className="section_image mr-3"
                />
                <h2>Desafíos</h2>
              </div>
              <hr />
              <ul>
                <li>
                  <b>Dependencia del monitoreo humano: </b> Las estaciones de
                  servicio han confiado tradicionalmente en el monitoreo humano
                  para detectar derrames.
                </li>
                <li>
                  <b>Falta de soluciones automáticas:</b> A pesar del avance
                  tecnológico, todavía hay una notable ausencia de soluciones
                  automáticas diseñadas específicamente para detectar derrames
                  de gasolina en estaciones de servicio.
                </li>
              </ul>
            </div>

            <div
              className="info_section"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="d-flex align-items-center mb-3">
                <img
                  src={SolucionImg}
                  alt="Solución"
                  className="section_image mr-3"
                />
                <h2>Solución</h2>
              </div>
              <hr />
              <ul>
                <li>
                  <b>Visión por Computadora y AI:</b> Mediante el uso de cámaras
                  de alta definición y sensores espectrales, complementados con
                  algoritmos de IA, el sistema propuesto pretende detectar
                  derrames en tiempo real.
                </li>
                <li>
                  <b>Prueba de concepto y demostración temprana:</b>{' '}
                  Demostración temprana para asegurar a las partes interesadas
                  sobre la eficacia de la solución.
                </li>
                <li>
                  <b>Integración sin problemas:</b> La solución ha sido diseñada
                  para integrarse sin problemas con las operaciones actuales de
                  las estaciones de servicio.
                </li>
              </ul>
            </div>

            <div
              className="results_section"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="d-flex align-items-center mb-3">
                <img
                  src={ResultadoImg}
                  alt="Resultados Esperados"
                  className="section_image mr-3"
                />
                <h2>Resultados Esperados</h2>
              </div>
              <hr />
              <p>
                Aunque la implementación completa aún está por realizarse, las
                expectativas son altas. Se espera que la solución disminuya
                drásticamente los riesgos asociados con los derrames de
                gasolina, mejorando la seguridad general.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HistoriaDeteccionGasolinaIA;
