// src/components/Historias/HistoriaSeguridadCajeros.js

import React from 'react';
import HojaImg from '../../assets/images/hoja.png';
import ResultadoImg from '../../assets/images/resultado.png';
import SolucionImg from '../../assets/images/solucion.png';
import DesafioImg from '../../assets/images/desafio.png';

function HistoriaSeguridadCajeros() {
  return (
    <>
      <section
        className="blog_detail_section"
        data-aos="fade-up"
        data-aos-duration={2000}
        data-aos-delay={200}
      >
        <div className="container">
          <div className="blog_inner_pannel">
            <div
              className="blog_info"
              data-aos="fade-up"
              data-aos-duration={2000}
            >
              <span className="date">12 Dec, 2022</span>
              <h1>
                Implementación de sistemas avanzados de seguridad para cajeros
                automáticos
              </h1>
            </div>

            <div
              className="info_section"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="d-flex align-items-center mb-3">
                <img
                  src={HojaImg}
                  alt="Solución"
                  className="section_image mr-3"
                />{' '}
                <h2>Visión General</h2>
              </div>
              <hr />
              <p>
                Una empresa líder en tecnología de seguridad en España ha estado
                comprometida con la innovación y la protección de activos
                financieros durante más de dos décadas. En el contexto de una
                era digital donde los ciberataques y fraudes son cada vez más
                sofisticados, la empresa buscó renovar y fortalecer la seguridad
                en los cajeros automáticos, uno de los principales puntos de
                interacción financiera.
              </p>
            </div>

            <div
              className="info_section"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="d-flex align-items-center mb-3">
                <img
                  src={DesafioImg}
                  alt="Desafíos"
                  className="section_image mr-3"
                />
                <h2>Desafíos</h2>
              </div>
              <hr />
              <ul>
                <li>
                  <b>Costo operativo y de mantenimiento: </b>Mantener un sistema
                  de seguridad avanzado es costoso, no solo en términos de
                  instalación inicial, sino también en su mantenimiento y
                  actualización regular para enfrentar amenazas emergentes.
                </li>
                <li>
                  <b>Tiempo de respuesta ante situaciones sospechosas:</b> En el
                  caso de un evento de seguridad, la respuesta rápida es
                  crucial. Los sistemas tradicionales a menudo tardan en
                  identificar y alertar sobre actividades sospechosas, lo que
                  puede resultar en pérdidas significativas.
                </li>
                <li>
                  <b>Integración y cumplimiento de normativas de seguridad: </b>
                  Con las regulaciones cambiantes y las normas de seguridad en
                  constante evolución, es esencial que cualquier solución sea
                  flexible y se adapte a los estándares más recientes.
                </li>
              </ul>
            </div>

            <div
              className="info_section"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="d-flex align-items-center mb-3">
                <img
                  src={SolucionImg}
                  alt="Solución"
                  className="section_image mr-3"
                />
                <h2>Solución</h2>
              </div>
              <hr />
              <ul>
                <li>
                  <b>Integración de visión por computadora y AI: </b>Utilizando
                  cámaras de alta definición y algoritmos de inteligencia
                  artificial, el sistema puede detectar comportamientos
                  sospechosos en tiempo real, como intentos de manipulación o
                  presencia de dispositivos de skimming.
                </li>
                <li>
                  <b>
                    IoT para comunicación inmediata con servicios de seguridad:
                  </b>
                  Con dispositivos conectados a través de Internet de las Cosas
                  (IoT), el cajero automático puede comunicarse instantáneamente
                  con servicios de seguridad y personal del banco para tomar
                  medidas inmediatas.
                </li>
                <li>
                  <b>Cumplimiento de normativas:</b> La solución se diseñó
                  teniendo en cuenta las regulaciones locales e internacionales,
                  garantizando que el banco no solo esté protegido contra
                  amenazas, sino que también cumpla con todos los estándares
                  regulatorios.
                </li>
              </ul>
            </div>

            <div
              className="results_section"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="d-flex align-items-center mb-3">
                <img
                  src={ResultadoImg}
                  alt="Solución"
                  className="section_image mr-3"
                />
                <h2>Resultados</h2>
              </div>
              <hr />
              <p>
                Tras la implementación del sistema, la empresa logró reducir
                significativamente los incidentes de seguridad en sus cajeros
                automáticos. El tiempo de respuesta ante situaciones sospechosas
                se redujo en un 80%, lo que llevó a una disminución en las
                pérdidas financieras. Además, la confianza del cliente en el uso
                de cajeros aumentó, fortaleciendo la relación banco-cliente y
                reafirmando el compromiso del banco con la innovación y la
                seguridad.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HistoriaSeguridadCajeros;
