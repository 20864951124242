import React from 'react';
import One from '../../assets/images/new/comunication.svg';
import Two from '../../assets/images/new/api.png';
import Three from '../../assets/images/new/alert.png';
import Four from '../../assets/images/new/cominication-data-01.png';

function SolutionSalidaDeDatos() {
  return (
    <>
      <section className="row_am communication_section">
        <div className="communication_inner">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div
                  className="communication_text"
                  data-aos="fade-in"
                  data-aos-duration={2000}
                  data-aos-delay={100}
                >
                  <span className="icon">
                    <img src={One} alt="imagesss" />
                  </span>
                  <div className="section_title">
                    <h2>Entrega y Aplicación de Datos</h2>
                    <p>
                      Transformamos los datos procesados en insights prácticos y
                      te los entregamos de forma que puedas usarlos de
                      inmediato.
                    </p>
                  </div>
                  <ul>
                    <li data-aos="fade-up" data-aos-duration={2000}>
                      <h3>Alertas</h3>
                      <p>
                        Recibe notificaciones en tiempo real basadas en eventos
                        o condiciones específicas.
                      </p>
                    </li>
                    <li data-aos="fade-up" data-aos-duration={2000}>
                      <h3>Interacciones Dinámicas</h3>
                      <p>
                        Haz preguntas y obtén respuestas basadas en el análisis
                        de todos los datos del negocio.
                      </p>
                    </li>
                    <li data-aos="fade-up" data-aos-duration={2000}>
                      <h3>Dashboards Personalizados</h3>
                      <p>Visualiza tus datos con dashboards personalizados.</p>
                    </li>
                    <li data-aos="fade-up" data-aos-duration={2000}>
                      <h3>Endpoints (APIs):</h3>
                      <p>
                        Integra nuestros servicios en tus aplicaciones
                        existentes con nuestras APIs.
                      </p>
                    </li>
                    <li data-aos="fade-up" data-aos-duration={2000}>
                      <h3>Ejecución Automatizada de Acciones</h3>
                      <p>
                        Nuestro sistema puede reaccionar automáticamente a los
                        cambios en tus datos, ejecutando acciones predefinidas.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="communication_image"
                  data-aos="fade-in"
                  data-aos-duration={1000}
                >
                  <img
                    data-aos="fade-in"
                    data-aos-duration={2000}
                    data-aos-delay={100}
                    className="moving_animation"
                    src={Two}
                    alt="imagesss"
                  />
                  <img
                    data-aos="fade-in"
                    data-aos-duration={2000}
                    data-aos-delay={100}
                    className="moving_animation"
                    src={Three}
                    alt="imagesss"
                  />
                  <img
                    data-aos="fade-in"
                    data-aos-duration={2000}
                    data-aos-delay={100}
                    className=""
                    src={Four}
                    alt="imagesss"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SolutionSalidaDeDatos;
