import React from 'react';
import My from '../../assets/images/new/lostman.png';
import Two from '../../assets/images/new/error-number.png';
import { Link } from 'react-router-dom';

import { FaArrowLeft } from 'react-icons/fa';

function Error() {
  return (
    <>
      <div className="error_page_bg">
        <div className="container">
          <section className="error_page_section">
            <div className="section_title">
              <h2>Oops! Página no encontrada!</h2>
            </div>
            <div className="error_graphic">
              <div className="lostman">
                <img src={My} alt="imagesss" />
              </div>
              <div className="errornumber">
                <img src={Two} alt="imagesss" />
              </div>
            </div>
            <div className="top_part">
              <Link
                to="/"
                data-aos="fade-up"
                data-aos-duration={2000}
                className="btn btn_main aos-init aos-animate"
              >
                <FaArrowLeft /> Volver al inicio
              </Link>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Error;
