import React from 'react';
import One from '../../assets/images/new/mail.png';
import Two from '../../assets/images/new/location.png';
import Three from '../../assets/images/new/phone.png';
import { Link } from 'react-router-dom';

function CanalesDeContacto() {
  const data = [
    {
      imgSrc: One,
      title: 'Contacta con ventas',
      description: 'Empieza una conversación con nuestro equipo de ventas',
      link: 'mailto:tech@Logiko.AI',
      linkText: 'tech@Logiko.AI',
    },
    {
      imgSrc: Two,
      title: 'Visita nuestra oficina',
      description: 'Reúnete con nosotros en nuestra oficina',
      link: 'https://goo.gl/maps/msGjt4dfwCf7v2My9',
      linkText: 'C. Flandes, 11, 29649 Mijas, Málaga',
    },
    {
      imgSrc: Three,
      title: 'Llámanos',
      description: 'Llámanos para hablar con un representante de ventas',
      link: 'tel:+34 678 29 91 35',
      linkText: '+34 678 29 91 35',
    },
  ];

  return (
    <section className="row_am contact_list_section">
      <div className="container">
        <div
          className="contact_list_inner"
          data-aos="fade-up"
          data-aos-duration={2000}
          data-aos-delay={100}
        >
          {data.map((item, index) => (
            <div key={index} className="c_list_card">
              <div className="icons">
                <img src={item.imgSrc} alt="imagesss" />
                <div className="dot_block">
                  <span className="dot_anim" />
                  <span className="dot_anim" />
                  <span className="dot_anim" />
                </div>
              </div>
              <div className="inner_text">
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                <Link to={item.link} target="_blank" className="text_btn">
                  {item.linkText}
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default CanalesDeContacto;
