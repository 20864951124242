// src/components/Historias/HistoryCard.js

import React from 'react';
import { Link } from 'react-router-dom';

function HistoryCard({ story }) {
  return (
    <>
      <div className="blog_list_section">
        <div className="blog_left_side">
          <section
            className="blog_detail_section"
            data-aos="fade-up"
            data-aos-duration={2000}
            data-aos-delay={200}
          >
            <div className="container">
              <div
                className="blog_panel"
                data-aos="fade-up"
                data-aos-duration={1500}
              >
                <div className="main_img">
                  <Link to={`/industrias/historia/${story.id}`}>
                    <img src={story.coverImage} alt="Cajero Automático" />{' '}
                  </Link>
                </div>
                <div className="blog_info">
                  <span className="date">{story.date}</span>
                  <h2>
                    <Link to={`/industrias/historia/${story.id}`}>
                      {story.title}
                    </Link>
                  </h2>
                  <p>{story.text}</p>
                  <div className="authore_block">
                    <div className="authore">
                      <div className="text">
                        <h4>Empresa de Seguridad XYZ</h4>
                        <span>Líder en Innovación de Seguridad</span>
                      </div>
                    </div>
                    <div className="blog_tag">
                      <span>{story.industry}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default HistoryCard;
