// src/components/Historias/HistoriaMaquinariaVialIA.js

import React from 'react';
import HojaImg from '../../assets/images/hoja.png';
import ResultadoImg from '../../assets/images/resultado.png';
import SolucionImg from '../../assets/images/solucion.png';
import DesafioImg from '../../assets/images/desafio.png';

function HistoriaMaquinariaVialIA() {
  return (
    <>
      <section
        className="blog_detail_section"
        data-aos="fade-up"
        data-aos-duration={2000}
        data-aos-delay={200}
      >
        <div className="container">
          <div className="blog_inner_pannel">
            <div
              className="blog_info"
              data-aos="fade-up"
              data-aos-duration={2000}
            >
              <span className="date">16 Jul, 2023</span>
              <h1>Innovación en Maquinaria Vial: Integración de IA e IoT</h1>
            </div>

            <div
              className="info_section"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="d-flex align-items-center mb-3">
                <img
                  src={HojaImg}
                  alt="Visión General"
                  className="section_image mr-3"
                />
                <h2>Visión General</h2>
              </div>
              <hr />
              <p>
                El desarrollo y mantenimiento de infraestructura vial es crucial
                para la conectividad y el crecimiento económico. Con el avance
                tecnológico, se ha buscado revolucionar el sector incorporando
                herramientas digitales que ofrezcan soluciones más eficientes y
                seguras.
              </p>
            </div>

            <div
              className="info_section"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="d-flex align-items-center mb-3">
                <img
                  src={DesafioImg}
                  alt="Desafíos"
                  className="section_image mr-3"
                />
                <h2>Desafíos</h2>
              </div>
              <hr />
              <ul>
                <li>
                  <b>Optimización de operaciones: </b> Mejorar la eficiencia y
                  precisión en proyectos viales.
                </li>
                <li>
                  <b>Mantenimiento predictivo:</b> Detectar problemas
                  potenciales en la maquinaria antes de que ocurran fallos.
                </li>
                <li>
                  <b>Seguridad laboral:</b> Garantizar la seguridad de los
                  operarios en zonas de trabajo.
                </li>
              </ul>
            </div>

            <div
              className="info_section"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="d-flex align-items-center mb-3">
                <img
                  src={SolucionImg}
                  alt="Solución"
                  className="section_image mr-3"
                />
                <h2>Solución</h2>
              </div>
              <hr />
              <ul>
                <li>
                  <b>Equipos conectados:</b> Maquinaria con sensores IoT para
                  enviar y recibir datos en tiempo real, mejorando la toma de
                  decisiones en el terreno.
                </li>
                <li>
                  <b>Inteligencia Artificial:</b> Análisis de datos para prever
                  desgastes o fallos, permitiendo un mantenimiento predictivo.
                </li>
                <li>
                  <b>Automatización y robótica:</b> Uso de maquinaria autónoma
                  que puede operar en condiciones peligrosas, reduciendo riesgos
                  laborales.
                </li>
              </ul>
            </div>

            <div
              className="results_section"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="d-flex align-items-center mb-3">
                <img
                  src={ResultadoImg}
                  alt="Resultados"
                  className="section_image mr-3"
                />
                <h2>Resultados</h2>
              </div>
              <hr />
              <p>
                La integración de IA e IoT en maquinaria vial no solo ha
                revolucionado la forma de trabajar, sino que también ha traído
                beneficios tangibles en términos de eficiencia, costos y
                seguridad. Las operaciones se realizan más rápidamente, los
                costos de mantenimiento se han reducido y, lo más importante,
                los trabajadores están más seguros en sus labores diarias.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HistoriaMaquinariaVialIA;
