import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import blocks from '../../assets/images/new/blocks.png';
import wave from '../../assets/images/new/wave.png';
import Logo from '../../assets/images/logo.png';

const Header = () => {
  const [headerScroll, setheaderScroll] = useState(false);
  const [click, setClick] = useState(false);
  const [navclick, setNavClick] = useState(false);
  useEffect(() => {
    const fixedHeader = () => {
      if (window.pageYOffset > 150) {
        setheaderScroll(true);
      } else {
        setheaderScroll(false);
      }
    };
    window.addEventListener('scroll', fixedHeader);
  }, []);
  return (
    <>
      <div className="anim_shapes">
        <span>
          <img src={blocks} alt="imagesss" />
        </span>
        <span className="moving_waves">
          <img src={wave} alt="imagesss" />
        </span>
      </div>

      <header className={headerScroll ? 'fixed fix_style' : 'fixed'}>
        <div className="container">
          <nav className="navbar navbar-expand-lg">
            <Link className="navbar-brand" to="/">
              <img src={Logo} alt="imagesss" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">
                <span
                  className={`toggle-wrap 
                  `}
                  onClick={() => {
                    setClick(!click);
                    setNavClick(!navclick);
                  }}
                >
                  <span className="toggle-bar" />
                </span>
              </span>
            </button>
            <div
              className={`navbar-collapse collapse ${
                navclick === true ? 'show' : ''
              }`}
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item active">
                  <Link className="nav-link" to="/">
                    Inicio
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/ai-manager">
                    Interacción con la IA
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link className="nav-link" to="/nosotros">
                    Nosotros
                  </Link>
                </li> */}
                <li className="nav-item">
                  <Link className="nav-link" to="/industrias">
                    Servicios
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contacto">
                    Contacto
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
