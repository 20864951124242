import React from 'react';

// Multimedia
import One from '../../assets/images/new/advance_01.png';
import Two from '../../assets/images/new/advance_02.png';
// import Three from '../../assets/images/new/advance_03.png';
import Four from '../../assets/images/new/advance_04.png';
import Five from '../../assets/images/new/advance_05.png';
// import { FaCamera } from 'react-icons/fa';
// import { FaCog } from 'react-icons/fa';
// import { FaShieldAlt } from 'react-icons/fa';
// import { FaClock } from 'react-icons/fa';

const features = [
  {
    image: Five,
    title: 'Cámaras Universales',
    description:
      'Integración flexible con cualquier cámara, desde webcams hasta profesionales.',
  },
  {
    image: Two,
    title: 'Integración de Maquinaria',
    description:
      'Conecta robots, maquinarias o equipos automatizados fácilmente.',
  },
  {
    image: One,
    title: 'Transmisión Segura',
    description:
      'Priorizamos la seguridad en todas las transmisiones de datos.',
  },
  {
    image: Four,
    title: 'Datos en Tiempo Real',
    description:
      'Decisiones inmediatas con información actualizada al instante.',
  },
];

const PrincipalesCaracterísticas = () => {
  return (
    <>
      <section className="advance_section">
        <div className="dotes_anim_bloack">
          {[...Array(8)].map((_, i) => (
            <div key={i} className={`dots dotes_${i + 1}`} />
          ))}
        </div>
        <div className="container">
          <div
            className="section_title"
            data-aos="fade-up"
            data-aos-duration={1500}
          >
            <h2>Versatilidad y Seguridad en Tiempo Real</h2>
            <p>
              Nuestras soluciones están diseñadas para adaptarse a múltiples
              fuentes de datos y garantizar transmisiones seguras. <br />{' '}
              Experimenta la transformación en tiempo real con máxima seguridad.
            </p>
          </div>
          <div className="row">
            {features.map((feature, index) => (
              <div key={index} className="col-lg-3 col-md-6">
                <div
                  className="ad_feature_card"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                >
                  <div className="grad_boder">
                    <div className="icon">
                      <img src={feature.image} alt="imagesss" />
                    </div>
                    <h3>{feature.title}</h3>
                    <p>{feature.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default PrincipalesCaracterísticas;
