import React from 'react';

function InteraccionesManager() {
  return (
    <>
      <section className="service_detail_section">
        <div className="container">
          <div className="service_left_side">
            <ul
              className="list_block"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <li>
                <h3>BrandScan - Enfrenta la Competencia con Conocimiento</h3>
                <p>
                  Olvídate de las conjeturas. BrandScan te brinda un panorama
                  claro de tus rivales en el mercado, ofreciendo la posibilidad
                  de analizar comentarios de sus clientes, reconocer sus puntos
                  fuertes y diseñar estrategias comerciales y de precios
                  sustentadas en datos concretos.
                </p>
              </li>
              <li>
                <h3>Interacción y Respuesta Proactiva</h3>
                <p>
                  AiManager te conecta directamente con tus clientes y
                  auditores. Mediante encuestas adaptadas, inspecciones y
                  herramientas afines, recolecta sus impresiones. Ante cualquier
                  evento, crea tareas automáticamente y aprovecha los
                  comentarios positivos como herramientas de marca en el entorno
                  digital.
                </p>
              </li>
              <li>
                <h3>Opciones y Tarifas Asequibles</h3>
                <p>
                  Adéntrate en AiManager sin desembolsar un euro. Accede a todas
                  sus funciones para un grupo de hasta 5 personas sin coste
                  alguno. Y si buscas más capacidades, solo añade 20€ por cada
                  integrante adicional.
                </p>
              </li>
              {/* <li>
                <h3>Ontime delivery</h3>
                <p>
                  Simply dummy text of the printing and typesetting inustry
                  lorem Ipsum has Lorem dollar summit.
                </p>
              </li> */}
            </ul>
            {/* <h3 data-aos="fade-up" data-aos-duration={1500}>
              How we build your software
            </h3>
            <p data-aos="fade-up" data-aos-duration={1500}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry lorem Ipsum has been the industrys standard dummy text
              ever since the when an utext ever since the when an unknown
              printer took a galley of type and.
            </p> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default InteraccionesManager;
