// industries.js

const data = {
  titulo: 'Servicios',
  subtitulo:
    'Descubre soluciones para diversos escenarios e industrias que desarrollamos.',
  industrias: [
    {
      nombre: 'Automotriz',
      descripcion:
        'Innovación y tecnología para una movilidad más inteligente.',
    },
    {
      nombre: 'Manufactura',
      descripcion: 'Optimización y eficiencia en procesos productivos.',
    },
    {
      nombre: 'Seguridad',
      descripcion: 'Soluciones avanzadas para protección y monitoreo.',
    },
    {
      nombre: 'Petróleo y Gas',
      descripcion: 'Maximiza la producción y gestión de energía.',
    },
    {
      nombre: 'Telecomunicaciones',
      descripcion: 'Conexiones confiables y servicios de vanguardia.',
    },
    {
      nombre: 'Farmacéutica',
      descripcion: 'Avances tecnológicos para cuidado y salud.',
    },
    {
      nombre: 'Transporte',
      descripcion: 'Movilidad inteligente, segura y eficiente.',
    },
  ],
};

export default data;
