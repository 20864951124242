// src/components/Historias/HistoriaTecnologiaMedicina.js

import React from 'react';
import HojaImg from '../../assets/images/hoja.png';
import ResultadoImg from '../../assets/images/resultado.png';
import SolucionImg from '../../assets/images/solucion.png';
import DesafioImg from '../../assets/images/desafio.png';

function HistoriaTecnologiaMedicina() {
  return (
    <>
      <section
        className="blog_detail_section"
        data-aos="fade-up"
        data-aos-duration={2000}
        data-aos-delay={200}
      >
        <div className="container">
          <div className="blog_inner_pannel">
            <div
              className="blog_info"
              data-aos="fade-up"
              data-aos-duration={2000}
            >
              <span className="date">13 Dec, 2022</span>
              <h1>
                Avances en la Tecnología Médica: Transformando el Cuidado del
                Paciente
              </h1>
            </div>

            <div
              className="info_section"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="d-flex align-items-center mb-3">
                <img
                  src={HojaImg}
                  alt="Visión General"
                  className="section_image mr-3"
                />
                <h2>Visión General</h2>
              </div>
              <hr />
              <p>
                La medicina ha sido una de las áreas que ha experimentado una
                transformación radical con la incorporación de la tecnología.
                Desde la digitalización de historiales médicos hasta la
                telemedicina, la tecnología ha mejorado la eficiencia, precisión
                y accesibilidad de los servicios de salud para pacientes y
                profesionales.
              </p>
            </div>

            <div
              className="info_section"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="d-flex align-items-center mb-3">
                <img
                  src={DesafioImg}
                  alt="Desafíos"
                  className="section_image mr-3"
                />
                <h2>Desafíos</h2>
              </div>
              <hr />
              <ul>
                <li>
                  <b>Acceso a la atención médica: </b>Para muchas personas,
                  especialmente en áreas rurales o desfavorecidas, acceder a
                  servicios médicos es un desafío significativo.
                </li>
                <li>
                  <b>Historiales médicos desorganizados:</b> La falta de un
                  sistema de gestión de registros coherente puede conducir a
                  errores médicos y complicaciones para los pacientes.
                </li>
                <li>
                  <b>Cuidado personalizado:</b> Proporcionar tratamientos y
                  recomendaciones específicas para cada individuo es esencial
                  para un cuidado óptimo.
                </li>
              </ul>
            </div>

            <div
              className="info_section"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="d-flex align-items-center mb-3">
                <img
                  src={SolucionImg}
                  alt="Solución"
                  className="section_image mr-3"
                />
                <h2>Solución</h2>
              </div>
              <hr />
              <ul>
                <li>
                  <b>Telemedicina:</b> La implementación de plataformas de
                  telemedicina permite a los pacientes consultar a especialistas
                  sin importar su ubicación geográfica.
                </li>
                <li>
                  <b>Sistemas de gestión electrónica:</b> Digitalizar
                  historiales médicos garantiza que estén organizados,
                  actualizados y accesibles para todos los profesionales de
                  salud pertinentes.
                </li>
                <li>
                  <b>Wearables y monitorización en tiempo real:</b> Los
                  dispositivos wearables permiten un seguimiento continuo del
                  estado de salud de los pacientes, proporcionando datos para un
                  cuidado personalizado.
                </li>
              </ul>
            </div>

            <div
              className="results_section"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="d-flex align-items-center mb-3">
                <img
                  src={ResultadoImg}
                  alt="Resultados"
                  className="section_image mr-3"
                />
                <h2>Resultados</h2>
              </div>
              <hr />
              <p>
                La adopción de tecnologías médicas avanzadas ha llevado a un
                cuidado del paciente más eficiente y personalizado. Los
                pacientes tienen ahora acceso a especialistas de renombre sin
                importar dónde se encuentren, y los médicos pueden tomar
                decisiones informadas con datos en tiempo real. Esta revolución
                tecnológica ha posicionado a la medicina en una trayectoria
                hacia un futuro más inclusivo y preciso.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HistoriaTecnologiaMedicina;
