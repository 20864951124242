import React from 'react';
import One from '../../assets/images/new/Analyze_Icon.svg';
import Server from '../../assets/images/new/server.png';
import IOT from '../../assets/images/new/iot.png';
import ComputerVision from '../../assets/images/new/computer-vision.jpg';

function SolucionEntradaDeDatos() {
  return (
    <>
      <section className="row_am analyze_section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div
                className="analyze_text"
                data-aos="fade-in"
                data-aos-duration={2000}
                data-aos-delay={100}
              >
                <span className="icon">
                  <img src={One} alt="imagesss" />
                </span>
                <div className="section_title">
                  <h2>Captura y Categorización de Datos</h2>
                  <p>
                    Recopilamos datos desde todas las fuentes posibles: cámaras,
                    maquinaria, sitios web y más. Nuestros sistemas procesan y
                    categorizan estos datos en tiempo real, proporcionando la
                    base para un análisis profundo.
                  </p>
                </div>
                <ul>
                  <li data-aos="fade-up" data-aos-duration={2000}>
                    <h3>Computer Vision</h3>
                    <p>
                      Extraemos información valiosa de imágenes y vídeos en
                      tiempo real.
                    </p>
                  </li>
                  <li data-aos="fade-up" data-aos-duration={2000}>
                    <h3>IoT</h3>
                    <p>
                      Capturamos datos de una multitud de dispositivos y
                      sensores.
                    </p>
                  </li>
                  <li data-aos="fade-up" data-aos-duration={2000}>
                    <h3>Datos de la Empresa</h3>
                    <p>
                      Integramos y categorizamos la información ya digitalizada
                      de tu empresa.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="analyze_image"
                data-aos="fade-in"
                data-aos-duration={1000}
              >
                <img
                  data-aos="fade-in"
                  data-aos-duration={2000}
                  data-aos-delay={100}
                  className="moving_animation"
                  src={Server}
                  alt="Server"
                />
                <img
                  data-aos="fade-in"
                  data-aos-duration={2000}
                  data-aos-delay={100}
                  className="moving_animation"
                  src={IOT}
                  alt="IOT"
                />
                <img
                  data-aos="fade-in"
                  data-aos-duration={2000}
                  data-aos-delay={100}
                  className=""
                  src={ComputerVision}
                  alt="ComputerVision"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SolucionEntradaDeDatos;
