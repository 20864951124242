import React from 'react';
import { Link } from 'react-router-dom';
import LogoWhite from '../../assets/images/new/logo_white.png';
import Two from '../../assets/images/new/contact_01.png';
import Three from '../../assets/images/new/contact_02.png';
import Four from '../../assets/images/new/contact_03.png';

import { SiMinutemailer } from 'react-icons/si';
import { FaLinkedin } from 'react-icons/fa';

function Main() {
  return (
    <>
      <footer>
        <div className="top_footer" id="contact">
          <div className="dotes_anim_bloack">
            <div className="dots dotes_1" />
            <div className="dots dotes_2" />
            <div className="dots dotes_3" />
            <div className="dots dotes_4" />
            <div className="dots dotes_5" />
            <div className="dots dotes_6" />
            <div className="dots dotes_7" />
            <div className="dots dotes_8" />
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="abt_side">
                  <div className="logo">
                    {' '}
                    <img src={LogoWhite} alt="imagesss" />
                  </div>
                  <p>
                    Implementamos soluciones de Inteligencia Artificial para
                    transformar tu negocio y desbloquear el valor de tus datos.
                  </p>
                  <div className="news_letter_block">
                    <form action="submit">
                      <div className="form-group">
                        <input
                          type="email"
                          placeholder="Tu email"
                          className="form-control"
                        />
                        <button className="btn">
                          <SiMinutemailer />
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-6 col-12">
                <div className="links">
                  <h3>Links útiles</h3>
                  <ul>
                    <li>
                      <Link to="/">Inicio</Link>
                    </li>
                    <li>
                      <Link to="/nosotros">Nosotros</Link>
                    </li>
                    <li>
                      <Link to="/industrias">Servicios</Link>
                    </li>
                    <li>
                      <Link to="/service-detail">Industrias</Link>
                    </li>
                    <li>
                      <Link to="/blog-list">Contacto</Link>
                    </li>
                  </ul>
                </div>
              </div>

              {/* <div className="col-lg-3 col-md-6 col-12">
                <div className="links">
                  <h3>Help &amp; Suport</h3>
                  <ul>
                    <li>
                      <Link to="/contacto">Contact us</Link>
                    </li>
                    <li>
                      <Link to="/faq">FAQs</Link>
                    </li>
                    <li>
                      <Link to="#">How it works</Link>
                    </li>
                    <li>
                      <Link to="#">Terms &amp; conditions</Link>
                    </li>
                    <li>
                      <Link to="#">Privacy policy</Link>
                    </li>
                  </ul>
                </div>
              </div> */}

              <div className="col-lg-3 col-md-6 col-12">
                <div className="try_out">
                  <h3>Contáctanos</h3>
                  <ul>
                    <li>
                      <span className="icon">
                        <img src={Two} alt="imagen" />
                      </span>
                      <div className="text">
                        <p>
                          Visita nuestra oficina <br /> C. Flandes, 11, 29649
                          Mijas, Málaga
                        </p>
                      </div>
                    </li>
                    <li>
                      <span className="icon">
                        <img src={Three} alt="imagen" />
                      </span>
                      <div className="text">
                        <p>
                          Llámanos
                          <Link to="tel:+34678299135">+34 678 29 91 35</Link>
                        </p>
                      </div>
                    </li>
                    <li>
                      <span className="icon">
                        <img src={Four} alt="imagen" />
                      </span>
                      <div className="text">
                        <p>
                          Escríbenos
                          <Link to="mailto:tech@Logiko.AI">tech@Logiko.AI</Link>
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom_footer">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <p>© Copyrights 2023. Todos los derechos reservados. </p>
              </div>
              <div className="col-md-4">
                <ul className="social_media">
                  <li>
                    <Link to="https://www.linkedin.com/company/logiko-ai/">
                      <FaLinkedin />
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="#">
                      <i className="icofont-twitter" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="icofont-instagram" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="icofont-pinterest" />
                    </Link>
                  </li> */}
                </ul>
              </div>
              <div className="col-md-4">
                <p className="developer_text">Logiko.AI</p>
              </div>
            </div>
          </div>
        </div>

        <div className="go_top" id="Gotop">
          <span>
            <i className="icofont-arrow-up" />
          </span>
        </div>
      </footer>
    </>
  );
}

export default Main;
