// src/components/Service/IndustriesList.js

import React from 'react';
import { Link } from 'react-router-dom';

import { FaArrowRight } from 'react-icons/fa';
import { FaPhone } from 'react-icons/fa';
import { BiSupport } from 'react-icons/bi';
import { FaLinkedin } from 'react-icons/fa';
import { FaFilePdf } from 'react-icons/fa';

import industriesData from '../../assets/data/industries';

import stories from '../../assets/data/stories';
import HistoryCard from '../Historias/HistoryCard';

function IndustriesList() {
  return (
    <>
      <section className="service_detail_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="service_left_side">
                <h1>Historias de éxito por Industria</h1>
                <p>
                  Dentro del mundo de la IA y el IoT, cada industria presenta
                  desafíos únicos. Hemos personalizado soluciones para enfrentar
                  y superar estos retos en diversos sectores
                </p>
                {stories.map((story, index) => (
                  <HistoryCard key={index} story={story} />
                ))}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="service_right_side">
                <div
                  className="service_list_panel"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                >
                  <h3>Industrias</h3>
                  <ul className="service_list">
                    {industriesData.industrias.map((industry, index) => (
                      <li key={index}>
                        <Link to="/service-detail">{industry.nombre}</Link>
                      </li>
                    ))}
                  </ul>
                </div>

                <div
                  className="side_contact_block"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                >
                  <div className="icon">
                    <BiSupport />
                  </div>
                  <h3>Trabajemos Juntos</h3>
                  <Link to="/contacto" className="btn btn_main">
                    CONTACTANOS <FaArrowRight />
                  </Link>
                  <p>
                    <Link to="tel:+34678299135">
                      <FaPhone /> +34 678 29 91 35
                    </Link>
                  </p>
                </div>
                <div className="action_btn">
                  <Link
                    to="https://www.linkedin.com/company/logiko-ai/"
                    target="blank"
                    className="btn"
                    data-aos="fade-up"
                    data-aos-duration={1500}
                  >
                    <span>
                      <FaLinkedin />{' '}
                    </span>
                    <p>Perfil de la empresa</p>
                  </Link>
                  <Link
                    to="assets/images/pdf-brochure.pdf"
                    target="blank"
                    className="btn"
                    data-aos="fade-up"
                    data-aos-duration={1500}
                  >
                    <span>
                      <FaFilePdf />{' '}
                    </span>
                    <p>Descargar presentación</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default IndustriesList;
