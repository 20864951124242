import React from 'react';
import AIM_metrics from '../../assets/images/AIM_metrics.png';

import { BsCheckCircleFill } from 'react-icons/bs';

function InteraccionAiManager() {
  return (
    <>
      <section className="row_am why_choose_section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div
                className="why_choose_text"
                data-aos="fade-in"
                data-aos-duration={2000}
                data-aos-delay={100}
              >
                <div className="section_title">
                  <h2>Tu Centro de Comando IA</h2>
                  <p>
                    Con AiManager, te sumerges en el núcleo de la Inteligencia
                    Artificial, accediendo a datos procedentes de múltiples
                    fuentes como robótica, IoT, cámaras y documentos, todo desde
                    una plataforma adaptable a móviles y escritorio.
                  </p>
                </div>
                <ul data-aos="fade-up" data-aos-duration={2000}>
                  <li>
                    <BsCheckCircleFill color="orange" className="mr-2" />
                    <b>AiMentor:</b> Chatea en tiempo real con un sistema IA que
                    comprende a fondo tus datos empresariales.
                  </li>
                  <li>
                    <BsCheckCircleFill color="orange" className="mr-2" />
                    <b>Detección por Cámara:</b>
                    Recibe alertas instantáneas de eventos significativos
                    identificados a través del análisis visual.
                  </li>
                  <li>
                    <BsCheckCircleFill color="orange" className="mr-2" />
                    <b>Geolocalización:</b> Ubica y responde a eventos a medida
                    que ocurren, basándote en su posición precisa.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="solution_image"
                data-aos="fade-in"
                data-aos-duration={2000}
                data-aos-delay={100}
              >
                <img src={AIM_metrics} alt="imagesss" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default InteraccionAiManager;
