import React from 'react';
import Header from './Header/Header';
import ContactanosFooter from './Contacto/Support';
import Footer from './Footer/Main';

const Layout = ({ children }) => {
  return (
    <div className="top_home_wraper white_option">
      <Header />
      {children}
      <ContactanosFooter />
      <Footer />
    </div>
  );
};

export default Layout;
