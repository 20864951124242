import React from 'react';
import { Link } from 'react-router-dom';
import One from '../../assets/images/new/service-1.svg';
import Two from '../../assets/images/new/service-2.svg';
import Three from '../../assets/images/new/service-3.svg';
import Four from '../../assets/images/new/service-4.svg';
import Five from '../../assets/images/new/service-5.svg';
import Six from '../../assets/images/new/service-6.svg';

import { BsArrowRight } from 'react-icons/bs';

import industriesData from '../../assets/data/industries';
const industryImages = [One, Two, Three, Four, Five, Six];

function Featured() {
  const renderIndustryCards = (industryList, indexOffset = 0) => {
    return (
      <div
        className="service_list_inner"
        data-aos="fade-up"
        data-aos-duration={2000}
        data-aos-delay={100}
      >
        {industryList.map((industry, index) => (
          <div key={index} className="s_list_card">
            <div className="icons">
              <img src={industryImages[index + indexOffset]} alt="industria" />{' '}
              <div className="dot_block">
                <span className="dot_anim" />
                <span className="dot_anim" />
                <span className="dot_anim" />
              </div>
            </div>
            <div className="inner_text">
              <h3>{industry.nombre}</h3>
              <p>{industry.descripcion}</p>
              <Link to="/industrias" className="btn text_btn">
                <BsArrowRight /> VER SOLUCIONES
              </Link>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <section className="row_am service_list_section">
        <div className="container">
          <div
            className="section_title"
            data-aos="fade-up"
            data-aos-duration={1500}
            data-aos-delay={100}
          >
            <h2>Industrias</h2>

            <p>
              Descubre soluciones para diversos escenarios e industrias que
              desarrollamos.
            </p>
          </div>
          {renderIndustryCards(industriesData.industrias.slice(0, 3), 0)}
          {renderIndustryCards(industriesData.industrias.slice(3, 6), 3)}
        </div>
      </section>
    </>
  );
}

export default Featured;
