// src/components/Historias/HistoriaAlmacenCongeladosIA.js

import React from 'react';
import HojaImg from '../../assets/images/hoja.png';
import ResultadoImg from '../../assets/images/resultado.png';
import SolucionImg from '../../assets/images/solucion.png';
import DesafioImg from '../../assets/images/desafio.png';

function HistoriaAlmacenCongeladosIA() {
  return (
    <>
      <section
        className="blog_detail_section"
        data-aos="fade-up"
        data-aos-duration={2000}
        data-aos-delay={200}
      >
        <div className="container">
          <div className="blog_inner_pannel">
            <div
              className="blog_info"
              data-aos="fade-up"
              data-aos-duration={2000}
            >
              <span className="date">14 Dec, 2022</span>
              <h1>
                Automatización de un Almacén Industrial de Congelados con
                Inteligencia Artificial
              </h1>
            </div>

            <div
              className="info_section"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="d-flex align-items-center mb-3">
                <img
                  src={HojaImg}
                  alt="Visión General"
                  className="section_image mr-3"
                />
                <h2>Visión General</h2>
              </div>
              <hr />
              <p>
                La conservación y gestión de productos congelados es esencial
                para garantizar la calidad y seguridad alimentaria. Con la
                evolución tecnológica, los almacenes industriales han buscado
                métodos más eficientes para optimizar sus operaciones, y la
                inteligencia artificial ha emergido como una solución
                innovadora.
              </p>
            </div>

            <div
              className="info_section"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="d-flex align-items-center mb-3">
                <img
                  src={DesafioImg}
                  alt="Desafíos"
                  className="section_image mr-3"
                />
                <h2>Desafíos</h2>
              </div>
              <hr />
              <ul>
                <li>
                  <b>Optimización del espacio: </b>Maximizar el uso del espacio
                  disponible para almacenar la mayor cantidad de productos.
                </li>
                <li>
                  <b>Conservación uniforme:</b> Mantener una temperatura
                  constante y adecuada en todas las áreas del almacén.
                </li>
                <li>
                  <b>Logística interna:</b> Facilitar la ubicación y movimiento
                  de productos de manera eficiente y segura.
                </li>
              </ul>
            </div>

            <div
              className="info_section"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="d-flex align-items-center mb-3">
                <img
                  src={SolucionImg}
                  alt="Solución"
                  className="section_image mr-3"
                />
                <h2>Solución</h2>
              </div>
              <hr />
              <ul>
                <li>
                  <b>Sistemas autónomos:</b> Uso de robots autónomos guiados por
                  IA para mover y almacenar productos, mejorando la eficiencia y
                  reduciendo errores.
                </li>
                <li>
                  <b>Monitoreo en tiempo real:</b> Sensores inteligentes
                  distribuidos en el almacén para monitorizar la temperatura y
                  garantizar una conservación uniforme.
                </li>
                <li>
                  <b>Software de gestión con IA:</b> Implementación de un
                  software avanzado para optimizar la logística interna,
                  predecir la demanda y gestionar el inventario.
                </li>
              </ul>
            </div>

            <div
              className="results_section"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="d-flex align-items-center mb-3">
                <img
                  src={ResultadoImg}
                  alt="Resultados"
                  className="section_image mr-3"
                />
                <h2>Resultados</h2>
              </div>
              <hr />
              <p>
                La incorporación de inteligencia artificial en el almacén
                industrial de congelados no solo optimizó las operaciones, sino
                que también mejoró la calidad y seguridad de los productos
                almacenados. Se redujeron los errores de logística, se garantizó
                una mejor conservación y se anticiparon las demandas, lo que
                resultó en una mayor satisfacción del cliente y una reducción de
                costes.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HistoriaAlmacenCongeladosIA;
