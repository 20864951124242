import React from 'react';
import { Link } from 'react-router-dom';

import { FaRegClock } from 'react-icons/fa';
import { FaPhone } from 'react-icons/fa';

function ContactanosFooter() {
  return (
    <>
      <section className="need_section">
        <div className="need_section_inner">
          <div className="container">
            <div className="need_block">
              <div className="need_text">
                <div className="section_title">
                  <h2>Contáctanos</h2>
                  <p>
                    <FaRegClock /> Lun - Vie: 9 am a 6 pm
                  </p>
                </div>
              </div>
              <div className="need_action">
                <Link to="tel:+34678299135" className="btn">
                  <FaPhone /> +34 678 29 91 35
                </Link>
                {/* <Link to="#faqBlock" className="faq_btn">
                  Read The FAQ{' '}
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactanosFooter;
